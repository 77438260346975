import React, { useContext, useEffect, useMemo, useState } from 'react';
import QuestionSharing from '../questionSharing/QuestionSharing';

import { CalculatorsContext, DraftContext } from '../../context';
import { addShare, getShare } from '../../api/rest/list';

const QuestionSharingWrapper = (props) => {
  const { categoryCode, questionId, model } = props;

  const { carbonQuestions, config } = useContext(CalculatorsContext);
  const { draft, user } = useContext(DraftContext);
  const [treeData, setTreeData] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState(null);
  const questionTree = useMemo(
    () =>
      carbonQuestions.reduce((sNodes, scope) => {
        const scopeNode = {
          title: scope.name,
          key: scope.code,
          children: scope.categories.reduce((cNodes, category) => {
            const categoryNode = {
              title: category.name,
              key: category.code,
              children: category.questions.map((question) => ({
                title: question.name,
                key: `${category.code}|${question.id}`,
                id: question.id,
                catCode: category.code,
                scopeCode: scope.code,
              })),
            };
            cNodes.push(categoryNode);
            return cNodes;
          }, []),
        };
        sNodes.push(scopeNode);
        return sNodes;
      }, []),
    [carbonQuestions]
  );

  useEffect(() => setTreeData(questionTree ?? []), [questionTree]);

  useEffect(() => {
    console.log({ draft, user, config });
  }, [draft, user, config]);

  useEffect(() => {
    if (!treeData || !categoryCode || !questionId) return;
    const selectedKey = `${categoryCode}|${questionId}`;
    const selected = treeData.reduce((acc, scope) => {
      const filtered = scope.children.filter((category) => category.key === categoryCode);
      if (filtered.length) acc.push(selectedKey, filtered[0].key, scope.key);
      return acc;
    }, []);
    setSelectedKeys([...selected]);
  }, [treeData, categoryCode, questionId]);

  const onResultCallback = ({ addressee, data }) => {
    if (!addressee || !data) return;
    console.log('data', data);
    const sender = {
      first_name: user.firstName,
      last_name: user.lastName,
      company_name: draft.companyName,
      tool_name: config.name,
    };
    const payload = {
      ...addressee,
      sender,
      calculator_id: draft.id,
      calculator_type: model,
      receivers: [{ id: user.id, type: 'USER' }],
      questions: [
        ...data.map((el) => ({
          outer_id: el.id,
          type: model,
          name: el.title,
          data: JSON.stringify({ category_code: el.catCode, scope_code: el.scopeCode }),
        })),
      ],
    };
    console.log('payload', payload);
    addShare(payload).then((res) => {
      console.log('res', res);
      getShare({ calculator_id: draft.id }).then((res2) => setInvitedUsers(res2.data.data));
    });
  };

  useEffect(() => {
    getShare({ calculator_id: draft.id }).then((res) => setInvitedUsers(res.data.data));
  }, []);

  return (
    <QuestionSharing
      treeData={treeData}
      selectedInit={selectedKeys}
      resultCallback={onResultCallback}
      invitedUsers={invitedUsers}
    />
  );
};

export default QuestionSharingWrapper;
