import { useContext, useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import InviteToAnswer from '../inviteToAnswer/InviteToAnswer';
import { Modal, Button, CheckBox, ANTD_COMPONENTS } from 'tt-ui-lib/core';
import { PartialLoader } from 'tt-ui-kit';
import { GET_ROLE, SEND_INVITE } from '../../api/apollo/api';
import styles from './QuestionSharing.module.scss';
import { ReactComponent as UpIco } from '../../assets/icons/expandUp.svg';
import { ReactComponent as DownIco } from '../../assets/icons/expandDown.svg';

const { Tree } = ANTD_COMPONENTS;

const QuestionSharing = (props) => {
  const { treeData, selectedInit = [], resultCallback, invitedUsers } = props;
  const [userResult, setUserResult] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [checkedNodes, setCheckedNodes] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const sendData = () => {
    resultCallback({
      addressee: { ...userResult },
      data: [...checkedNodes],
    });
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onOpen = () => {
    setIsModalOpen(true);
  };

  const onAddresseeChose = (result) => {
    setUserResult(result);
    onOpen();
  };

  const onQuestionsChoose = (result) => {
    sendData();
    onClose();
  };

  const onExpand = (expandedKeysValue, e) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue, e) => {
    setCheckedKeys(checkedKeysValue);
    setCheckedNodes(e.checkedNodes.filter((n) => !n.children));
  };

  useEffect(() => {
    if (selectedInit) {
      setAutoExpandParent(true);
      setSelectedKeys([...selectedInit]);
      setExpandedKeys([...selectedInit]);
    }
  }, [selectedInit]);

  const customSwitcherIcon = (node) => {
    const isExpanded = expandedKeys.includes(node.eventKey);
    return isExpanded ? (
      <DownIco style={{ width: 10, height: 10 }} />
    ) : (
      <UpIco style={{ width: 10, height: 10 }} />
    );
  };

  return (
    <>
      <InviteToAnswer
        roleQuery={GET_ROLE}
        sendInviteQuery={SEND_INVITE}
        onCloseCallback={onAddresseeChose}
        invitedUsers={invitedUsers}
      />
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => onClose(false)}
          onClick={(e) => e.stopPropagation()}
          title={<div style={{ paddingRight: '50px' }}>Select questions</div>}
          className={styles.modal}
          closeOnlyByControls
        >
          <div className={styles.modalContent}>
            <div className={styles.modalAbout}>
              Please select the questions you need your colleague to answer.
            </div>
            <div className={styles.treeWrapper}>
              {treeData && (
                <Tree
                  checkable
                  onExpand={onExpand}
                  expandedKeys={expandedKeys}
                  autoExpandParent={autoExpandParent}
                  onCheck={onCheck}
                  checkedKeys={checkedKeys}
                  selectedKeys={selectedKeys}
                  treeData={treeData}
                  switcherIcon={(nodeProps) => customSwitcherIcon(nodeProps)}
                />
              )}
            </div>
            <div className={styles.buttonWrapper}>
              <Button className={styles.modal_button} type="default" onClick={onClose}>
                CANCEL
              </Button>
              <Button
                className={styles.modal_button}
                disabled={loader}
                type="primary"
                onClick={onQuestionsChoose}
              >
                Request a pesponse
              </Button>
            </div>
          </div>
          {loader && (
            <div>
              <PartialLoader />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default QuestionSharing;
